import React, { useRef } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-tanga-app.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const testimonialsRef = useRef(null);
  const faqRef = useRef(null);

  return (
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <div id="features" ref={featuresRef}>
        <Features
          subheading={<Subheading>Fonctionnalités</Subheading>}
          heading={
            <>
              Nous offrons un{" "}
              <HighlightedText>Service Exceptionnel.</HighlightedText>
            </>
          }
        />
        </div>

        <MainFeature
          subheading={<Subheading>Qualité Supérieure</Subheading>}
          imageSrc={heroScreenshotImageSrc}
          imageBorder={true}
          imageDecoratorBlob={true}
        />
        <FeatureWithSteps
          subheading={<Subheading>ÉTAPES</Subheading>}
          heading={
            <>
              Facile à <HighlightedText>Démarrer.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <MainFeature2
          subheading={<Subheading>VALEURS</Subheading>}
          heading={
            <>
              Nous Respectons Toujours Nos{" "}
              <HighlightedText>Principes.</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Abordable",
              description:
                "Nous vous promettons d'offrir le meilleur tarif possible, en adéquation avec les standards de l'industrie.",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "Professionnalisme",
              description:
                "Nous vous assurons que notre système est conçu et développé par des professionnels expérimentés.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
        <div id="pricing" ref={pricingRef}>
          <Pricing
            subheading={<Subheading>Tarification</Subheading>}
            heading={
              <>
                Des Plans{" "}
                <HighlightedText>Raisonnables et Flexibles.</HighlightedText>
              </>
            }
            plans={[
              {
                name: "Basique",
                price: "15000 XOF",
                duration: "par mois",
                mainFeature: "Pour les Petites Entreprises",
                features: [
                  "Jusqu'à 500 visiteurs/mois",
                  "Enregistrement numérique",
                  "Rapports de base",
                  "Assistance par email",
                ],
              },
              {
                name: "Professionnel",
                price: "20000 XOF",
                duration: "par mois",
                mainFeature: "Pour les Moyennes Entreprises",
                features: [
                  "Jusqu'à 2000 visiteurs/mois",
                  "Intégration avec systèmes de sécurité",
                  "Rapports avancés",
                  "Assistance prioritaire",
                ],
                featured: true,
              },
              {
                name: "Entreprise",
                price: "Sur mesure",
                duration: "par mois",
                mainFeature: "Pour les Grandes Entreprises",
                features: [
                  "Nombre illimité de visiteurs",
                  "Personnalisation complète",
                  "API pour intégrations",
                  "Gestionnaire de compte dédié",
                ],
              },
            ]}
          />
        </div>
        <div id="testimonials" ref={testimonialsRef}>
          <Testimonial
            subheading={<Subheading>Témoignages</Subheading>}
            heading={
              <>
                Nos Clients <HighlightedText>Nous Adorent.</HighlightedText>
              </>
            }
            testimonials={[
              {
                stars: 4,
                profileImageSrc:
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                heading: "Expérience Utilisateur Incroyable",
                quote:
                  "Tanga a révolutionné notre gestion des visiteurs. Le processus d'enregistrement est désormais rapide, efficace et sécurisé. Nos clients et notre équipe de sécurité sont ravis de l'amélioration apportée à notre accueil.",
                customerName: "Sophie Dubois",
                customerTitle: "Directrice de la Sécurité, TechFrance SA",
              },
              {
                stars: 5,
                profileImageSrc:
                  "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                heading: "Une Solution Complète et Intuitive",
                quote:
                  "En tant que responsable de la sécurité, j'apprécie particulièrement la facilité d'utilisation de Tanga et ses fonctionnalités avancées. Les rapports en temps réel et l'intégration avec nos systèmes existants ont considérablement amélioré notre efficacité.",
                customerName: "Marc Lefevre",
                customerTitle: "Chef de la Sécurité, GrandCorp",
              },
            ]}
          />
        </div>
        <div id="faq" ref={faqRef}>
          <FAQ
            subheading={<Subheading>FAQ</Subheading>}
            heading={
              <>
                Vous avez des <HighlightedText>Questions ?</HighlightedText>
              </>
            }
            faqs={[
              {
                question:
                  "Tanga est-il personnalisable pour répondre aux besoins spécifiques de notre entreprise ?",
                answer:
                  "Oui, Tanga est entièrement personnalisable. Nous comprenons que chaque entreprise a des besoins uniques en matière de gestion des visiteurs. Notre équipe travaillera avec vous pour adapter le système à vos exigences spécifiques.",
              },
              {
                question:
                  "Comment Tanga améliore-t-il la sécurité de nos locaux ?",
                answer:
                  "Tanga renforce la sécurité en automatisant le processus d'enregistrement, en générant des badges visiteurs, en effectuant des vérifications en temps réel et en fournissant une vue d'ensemble instantanée de tous les visiteurs présents dans vos locaux.",
              },
              {
                question:
                  "Tanga peut-il s'intégrer à nos systèmes de sécurité existants ?",
                answer:
                  "Absolument. Tanga est conçu pour s'intégrer facilement avec une variété de systèmes de sécurité, de contrôle d'accès et de gestion des bâtiments. Notre équipe technique vous guidera tout au long du processus d'intégration.",
              },
              {
                question:
                  "Quelle est la durée du contrat et existe-t-il une période d'essai ?",
                answer:
                  "Nos contrats sont généralement annuels, mais nous offrons également des options plus flexibles. Nous proposons une période d'essai gratuite de 30 jours pour vous permettre de tester Tanga et de voir comment il peut améliorer votre gestion des visiteurs.",
              },
              {
                question:
                  "Comment gérez-vous la protection des données et la conformité au RGPD ?",
                answer:
                  "La protection des données est notre priorité. Tanga est entièrement conforme au RGPD. Nous utilisons des protocoles de sécurité avancés pour protéger toutes les données des visiteurs, et nous fournissons des outils pour vous aider à respecter vos obligations en matière de protection des données.",
              },
              {
                question:
                  "Quel type de support offrez-vous aux clients de Tanga ?",
                answer:
                  "Nous offrons un support complet à tous nos clients. Cela inclut une assistance par email et téléphone, une documentation détaillée, des webinaires de formation réguliers et, pour nos clients entreprise, un gestionnaire de compte dédié.",
              },
            ]}
          />
        </div>

        <GetStarted />
        <Footer />
      </AnimationRevealPage>
  );
};
